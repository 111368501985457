<template>
  <Head :title="`Edit ${task.title}`"></Head>
  <s-page-block size="md">
    <s-page-header heading="Edit Task" class="pb-4">
      <template #actions>
        <s-dropdown-menu
          :items="[
            {
              label: $t('superAdmin'),
              href: route('admin.tasks.show', {task: task.id}),
              icon: 'account-supervisor-circle',
            },
            ...(task.id
              ? [
                  {
                    label: 'Feedbacks',
                    href: route('admin.tasks.feedbacks.index', {task: task.id}),
                    icon: 'comment-quote',
                  },
                ]
              : []),
          ]"
        >
          <template #trigger="{open}">
            <button class="btn sm red-light !bg-white flex items-center gap-1.5">
              <s-icon :name="open ? 'close' : 'lock'" size="18" class="opacity-70 transition-all" />
              {{ $t('admin') }}
            </button>
          </template>
        </s-dropdown-menu>
        <s-btn
          v-if="formComponent"
          @click.prevent="copyJsonToClipboard"
          color="secondary"
          icon="code-json"
        >
          JSON Export
        </s-btn>
        <s-btn
          v-if="task.id"
          class="flex-1 max-w-[14rem]"
          color="blue-light"
          icon="eye"
          :href="route('tasks.show', {task: task.id})"
        >
          {{ $t('actions.preview') }}
        </s-btn>
      </template>
    </s-page-header>

    <!--  Task Type Form -->
    <template v-if="formComponent">
      <component
        :is="formComponent"
        :institutions="institutions"
        :topics="topics"
        :modelsMetadata="modelsMetadata"
        :modelValue="task"
        :errors="errors"
      />
    </template>
  </s-page-block>
</template>
<script setup lang="ts">
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {computed, defineAsyncComponent} from 'vue';
import {Institution} from '../../types/entities/institution';
import {route} from 'ziggy-js';
import SBtn from '../../design-system/SBtn.vue';
import SIcon from '../../design-system/SIcon.vue';
import {Head, usePage} from '@inertiajs/vue3';
import {useFlash} from '../../composables/useFlash';
import {LlmMetadata} from '../../types/entities/llmMetadata';
import TaskTopicDto = App.DTOs.Tasks.TaskTopicDto;
import SDropdownMenu from '../../design-system/SDropdownMenu.vue';

const props = defineProps<{
  task: Record<string, any>;
  institutions: Institution[];
  topics: TaskTopicDto[];
  modelsMetadata: LlmMetadata[];
  errors?: Record<string, any>;
}>();

const page = usePage<{
  loggedInUser: {
    isSuperUser: boolean;
  };
}>();

const flash = useFlash();

const formComponent = computed(() => {
  return defineAsyncComponent(() => {
    return import(`./${props.task?.type}/Form.vue`).catch(() => {
      return import('./Edit/FallbackForm.vue');
    });
  });
});

const copyJsonToClipboard = () => {
  navigator.clipboard
    .writeText(
      JSON.stringify(
        props.task,
        (key, value) => {
          // Remove the root ID param
          if (key === 'id') {
            return undefined;
          }

          return value;
        },
        2
      )
    )
    .then(() => {
      flash?.value.set('success', 'Task JSON copied to clipboard');

      setTimeout(() => {
        flash?.value?.dismiss('success');
      }, 2500);
    });
};
</script>
