<template>
  <Head
    :title="
      t('page.title', {
        course: course.code,
        assignment: assignment.name,
        student: student.email,
      })
    "
  ></Head>
  <s-page-block size="sm">
    <s-page-header :heading="t('page.header')" class="mb-1" />
    <p class="mb-3">
      {{
        t('page.description', {
          course: course.code,
          assignment: assignment.name,
          studentFirst: student.firstName,
          studentLast: student.lastName,
          studentEmail: student.email,
        })
      }}
    </p>
    <form @submit.prevent="submit">
      <div class="w-full flex gap-4 mb-4">
        <s-input-field
          id="localHours"
          v-model.number="localForm.hours"
          type="number"
          :suffix="t('time.hours')"
          :aria-label="t('form.extensionHours')"
          min="0"
        />
        <s-input-field
          id="localMinutes"
          v-model.number="localForm.minutes"
          :error="form.errors.minutes"
          type="number"
          :suffix="t('time.minutes')"
          :aria-label="t('form.extensionMinutes')"
          min="0"
        />
      </div>
      <div class="flex-1 flex flex-wrap items-start justify-between gap-3 pt-1">
        <s-btn
          class="flex-1 w-full"
          color="white"
          icon="close"
          :href="
            route('courses.assignments.students.timer_extensions.index', [
              props.course.id,
              props.assignment.id,
              props.student.id,
            ])
          "
        >
          {{ t('form.cancel') }}
        </s-btn>
        <s-btn
          type="submit"
          class="flex-1 justify-self-end"
          icon="timer-outline"
          :processing="form.processing"
        >
          {{ t('actions.update') }}
        </s-btn>
      </div>
    </form>
  </s-page-block>
</template>
<script setup lang="ts">
import {ref, watch} from 'vue';
import {Head, useForm} from '@inertiajs/vue3';
import {Assignment} from '../../types/entities/assignment';
import {Course} from '../../types/entities/course';
import {User} from '../../types/entities/user';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {useI18n} from 'vue-i18n';
import SBtn from '../../design-system/SBtn.vue';
import SInputField from '../../design-system/SInputField.vue';
import {route} from 'ziggy-js';

const props = defineProps<{
  course: Course;
  assignment: Assignment;
  student: User;
  timer: UserAssignmentTimerDto;
  extension: any;
  redirect?: string;
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const form = useForm({
  minutes: props.extension.minutes,
});

const localForm = ref({
  hours: Math.floor(props.extension.minutes / 60),
  minutes: props.extension.minutes % 60,
});

watch(
  localForm,
  (value) => {
    form.minutes = Math.floor(value.hours * 60) + Math.floor(value.minutes);
  },
  {deep: true}
);

const submit = () => {
  form.put(
    route('courses.assignments.students.timer_extensions.update', {
      course: props.course.id,
      assignment: props.assignment.id,
      user: props.student.id,
      timerExtension: props.extension.id,
      _query: {
        redirect: props.redirect,
      },
    })
  );
};
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Edit Timer Extension - {student} - {assignment} - {course}",
      "header": "Edit Timer Extension",
      "description": "Edit timer extension for {studentFirst} {studentLast} ({studentEmail}) for {assignment} in {course}."
    },
    "form": {
      "extensionHours": "Extension Hours",
      "extensionMinutes": "Extension Minutes",
      "cancel": "Cancel"
    },
    "actions": {
      "update": "Update Extension"
    }
  },
  "fr": {
    "page": {
      "title": "Modifier la prolongation de temps - {student} - {assignment} - {course}",
      "header": "Modifier la prolongation de temps",
      "description": "Modifier la prolongation de temps pour {studentFirst} {studentLast} ({studentEmail}) pour {assignment} dans {course}."
    },
    "form": {
      "extensionHours": "Heures de prolongation",
      "extensionMinutes": "Minutes de prolongation",
      "cancel": "Annuler"
    },
    "actions": {
      "update": "Mettre à jour la prolongation"
    }
  }
}
</i18n>
