<template>
  <Head
    :title="`${assignment.course.code} - ${assignment.name} - ${t('task')} #${taskNumber}`"
  ></Head>
  <s-page-block size="full" class="flex flex-col items-stretch justify-start">
    <h1 class="sr-only">{{ t('task') }} #{{ taskNumber }}</h1>
    <!-- Assignment Grading Header -->
    <div
      class="flex-1 w-full grid grid-rows-[auto_auto_auto] xl:grid-rows-[1fr_auto] xl:grid-cols-[1fr_23rem] 2xl:grid-cols-[1fr_28rem] gap-6 xl:gap-x-8"
    >
      <!-- Response & File Uploads -->
      <section
        class="relative w-full card-soft border border-gray-150 stack vertical flex-1 min-w-0"
      >
        <!-- Admin button(s) -->
        <div
          v-if="canUpdateTask"
          class="flex absolute top-0 right-0 z-[21] rounded-bl-xl rounded-tr border-l border-b border-blue-100 overflow-hidden shadow-sm"
        >
          <s-btn
            icon="pencil"
            size="sm"
            color="primary-white"
            :href="route('tasks.edit', {task: task.id})"
            class="!rounded-none last:!rounded-tr !border-b-0 !border-l-0 !border-blue-200/300"
          >
            {{ t('editTask') }}
          </s-btn>
          <s-btn
            v-if="page.props.loggedInUser.isSuperUser"
            icon="pencil"
            size="sm"
            color="primary-white"
            :href="route('tasks.metadata', {task: task.id})"
            external
            class="!rounded-none last:!rounded-tr !border-b-0 !border-l-0 !border-blue-200/300"
          >
            {{ t('metadata') }}
          </s-btn>
        </div>

        <!-- Legacy Task -->
        <div class="flex-1 px-5 md:px-7 py-6 z-20">
          <legacy-task
            :seed="page.props.loggedInUser.id"
            :task="task"
            :task-state="taskState"
            :working-response-copy="workingResponseCopy"
            :stored-response-id="storedResponse?.id"
            :stored-response-content="storedResponse?.content"
            :stored-response-is-draft="!!storedResponse?.isDraft"
            :stored-response-attachments="props.storedResponse?.attachments"
            :stored-response-feedback-by-parts="props.feedbackByPart"
            :stored-response-part-grades="props.partGrades"
            @update:responseData="
              responseData = $event;
              persistAfterTick(debouncedStoreDraft);
            "
            @update:taskAttachments="
              filesToUpload = $event;
              persistAfterTick(debouncedStoreDraft);
            "
            @submit-response="
              responseData = $event.response;
              persistAfterTick(submitResponse);
            "
            :can="{
              createAttachment: true,
              deleteAttachments: true,
              viewGradeBreakdown: canViewGradeBreakdown,
            }"
          />
        </div>
        <!-- End Legacy Task -->

        <!-- Task Links -->
        <div
          v-if="
            !courseLikeAssignment?.hideHelpItems &&
            (task.textbookLink || (task.taskType !== 'WatchVideoTask' && task.youtubeVideoId))
          "
          class="px-5 md:px-7 pb-6 flex gap-3"
        >
          <div v-if="task.textbookLink" class="flex-1 max-w-[10rem]">
            <s-textbook-link :task="task" />
          </div>
          <div
            v-if="task.taskType !== 'WatchVideoTask' && task.youtubeVideoId"
            class="flex-1 max-w-[10rem]"
          >
            <s-btn
              color="gray"
              size="sm"
              icon="youtube"
              target="_blank"
              :href="`https://www.youtube.com/watch?v=${task.youtubeVideoId}`"
              external
            >
              {{ $t('video') }}
            </s-btn>
          </div>
        </div>
        <!-- End Task Links -->

        <!-- File Uploads -->
        <div
          v-if="props.storedResponse?.attachments?.length"
          class="border-t border-gray-200 px-5 lg:px-6 py-3.5 flex"
          :class="
            !storedResponse?.attachments?.length
              ? 'bg-gray-100'
              : 'bg-gradient-to-b from-gray-50 via-transparent to-transparent'
          "
        >
          <s-response-attachment-list
            :response-id="storedResponse ? storedResponse.id : null"
            :attachments="storedResponse?.attachments ?? []"
            :errors="errors"
            show-delete
            :props-to-reload-on-delete="storeProps"
          ></s-response-attachment-list>
        </div>
        <!-- End File Uploads -->

        <!-- Response Info & Actions -->
        <response-info-area
          :course="assignment.course"
          :assignment="assignment"
          :task="task"
          :assignment-tasks="assignmentTasks"
          :draft-task-ids="draftTaskIds"
          :response="storedResponse"
          :task-responses="taskResponses"
          v-model:selected-response="selectedResponse"
          :student-assignment-task-grades="studentAssignmentTaskGrades"
          :attempted-task-ids="attemptedTaskIds"
          :due-date="courseLikeAssignment?.dueDate"
          :assignment-extension="extension"
        >
          <template #actions>
            <div class="flex flex-wrap gap-3 flex-1 items-center justify-end">
              <draft-status-action
                v-if="storedResponse?.isDraft"
                :disabled="showProcessingBadge"
                :discard-draft="
                  () => {
                    router.delete(
                      route('web.courses.assignments.tasks.responses.destroy', {
                        course: assignment.course.id,
                        assignment: assignment.id,
                        task: task.id,
                        response: selectedResponse?.id,
                      })
                    );
                  }
                "
              />

              <div
                class="flex items-center rounded-full border"
                :class="{
                  'bg-gray-100 border-gray-200 text-gray-600 shadow-inset': !noMoreAttempts,
                  'bg-yellow-100 border-yellow-200 text-yellow-700 shadow-inset': noMoreAttempts,
                }"
              >
                <s-tooltip v-if="maxAttempts">
                  <template #tooltip>
                    <p class="heading font-bold">
                      <template v-if="noMoreAttempts">
                        {{ t('noMoreAttempts') }}
                      </template>
                      <template v-else-if="finalAttempt">
                        {{ t('finalAttempt') }}
                      </template>
                    </p>
                    <p>
                      <i18n-t keypath="tooltips.xOfYAttemptsMade">
                        <template #count>{{ numTaskAttempts }}</template>
                        <template #max>{{ maxAttempts + additionalAttempts }}</template>
                      </i18n-t>
                    </p>
                    <p v-if="additionalAttempts > 0" class="small">
                      <i18n-t keypath="tooltips.attemptsBreakdown">
                        <template #base>{{ maxAttempts }}</template>
                        <template #additional>{{ additionalAttempts }}</template>
                      </i18n-t>
                    </p>
                  </template>
                  <div class="flex items-center gap-1 px-4 whitespace-nowrap">
                    <s-icon
                      v-if="finalAttempt || noMoreAttempts"
                      name="alert"
                      size="18"
                      class="text-s-red-400 opacity-70 -ml-1"
                      aria-hidden="true"
                    />
                    <s-icon
                      v-else-if="additionalAttempts"
                      name="information"
                      size="18"
                      class="text-blue-500 opacity-70 -ml-1"
                      aria-hidden="true"
                    />
                    <span class="flex items-center gap-0.5">
                      {{ numTaskAttempts }}
                      <span class="opacity-70">/</span>
                      {{ maxAttempts + additionalAttempts }}
                    </span>
                  </div>
                </s-tooltip>
                <span class="-m-px bg-white rounded-full">
                  <s-tooltip
                    :text="submitTooltipText || ''"
                    :disabled="!isStoreSubmitDisabled"
                    position="top"
                  >
                    <s-btn
                      @click="persistAfterTick(submitResponse)"
                      :processing="submittingResponse || showProcessingBadge"
                      :disabled="isStoreSubmitDisabled"
                    >
                      <span v-if="storingDraft" class="animate-pulse">
                        {{ t('badges.savingDraft') }}
                      </span>
                      <span v-else-if="uploadingFiles" class="animate-pulse">
                        {{ t('badges.uploadingFiles') }}
                      </span>
                      <span v-else>
                        {{ submittingResponse ? t('submitting') : t('button.submitResponse') }}
                      </span>
                    </s-btn>
                  </s-tooltip>
                </span>
              </div>
            </div>
          </template>
        </response-info-area>
        <!-- End Response Info & Actions -->
      </section>
      <!-- End Response & File Uploads -->

      <!-- Assignment Info & Task Navigation -->
      <div class="flex-1 flex flex-col-reverse xl:flex-col gap-5 w-full">
        <timer v-if="timer" :timer="timer" />
        <!-- Assignment Info -->
        <assignment-info-student
          :assignment="assignment"
          :student="student"
          :name="assignment.name"
          :course="assignment.course"
          :startDate="courseLikeAssignment?.startDate"
          :dueDate="courseLikeAssignment?.dueDate"
          :extendedStartDate="extension?.newStartDate"
          :extendedDueDate="extension?.newDueDate"
          :totalMarks="assignmentTotalMarks"
          :grade="assignmentGrade"
          :can-view-grades="canViewGrades"
          :lateResponsePenaltyPolicy="courseLikeAssignment?.lateResponsePenaltyPolicy"
        />
        <!-- Task Navigation -->
        <div class="flex flex-col gap-5">
          <div
            class="flex flex-wrap items-center justify-between gap-x-2 gap-y-3 text-base font-medium text-gray-600"
          >
            <span class="flex-1 flex text-sm">
              <s-badge
                :color="numAttemptedTasks < assignmentTasks.length ? 'gray' : 'green'"
                class="whitespace-nowrap font-medium"
              >
                <s-icon
                  v-if="numAttemptedTasks >= assignmentTasks.length"
                  name="check-bold"
                  size="16"
                  class="opacity-70"
                  aria-hidden="true"
                />
                <i18n-t keypath="xOfYTasksSubmitted">
                  <template #x>
                    <strong class="font-bold">{{ numAttemptedTasks }}</strong>
                  </template>
                  <template #y>
                    <strong class="font-bold">{{ assignmentTasks.length }}</strong>
                  </template>
                </i18n-t>
              </s-badge>
            </span>
            <button
              @click="periodicTableVisible = true"
              class="py-1.5 px-2 text-blue-500 opacity-70 hover:opacity-100"
            >
              <s-icon name="periodic-table" size="24" />
              <span class="sr-only">{{ t('button.periodicTable') }}</span>
            </button>
            <popup-periodic-table v-model="periodicTableVisible" />
            <div class="relative flex flex-wrap items-center gap-x-3 gap-y-1">
              <response-legend-button />
            </div>
          </div>
          <div
            class="justify-start items-stretch gap-2"
            :class="
              assignmentTasks.length > 12
                ? 'grid grid-cols-[repeat(auto-fit,_minmax(2.5rem,_1fr))]'
                : 'flex flex-wrap'
            "
          >
            <s-link
              v-for="task in assignmentTasks"
              :href="
                route('web.courses.assignments.tasks.show', {
                  course: props.assignment.course.id,
                  assignment: props.assignment.id,
                  task: task.id,
                })
              "
              preserve-state
              :only="taskProps"
              class="relative"
              :class="determineTaskNavItemClasses(task.id, props.task.id, assignmentTasks.length)"
            >
              <s-icon
                v-if="draftTaskIds.includes(task.id)"
                name="pencil"
                class="opacity-70 -ml-1.5"
                :class="props.task.id === task.id ? 'text-white' : 'text-gray-600'"
                size="18"
              />
              {{ task.orderingIndex }}
              <s-icon
                v-if="taskHasAttachment(task.id)"
                name="paperclip"
                class="opacity-70 -mr-2.5"
                size="20"
              />
              <span v-if="draftTaskIds.includes(task.id)" class="-mr-0.5"></span>
            </s-link>
          </div>
          <div class="flex-1 flex gap-2">
            <s-btn
              @click="prevTask"
              icon="arrow-left"
              class="w-full"
              :disabled="props.task.id === props.assignmentTasks[0].id"
            >
              {{ t('button.previousTask') }}
            </s-btn>
            <s-btn
              @click="nextTask"
              color="primary"
              :disabled="
                props.task.id === props.assignmentTasks[props.assignmentTasks.length - 1].id
              "
              class="w-full"
              icon="arrow-right"
              icon-end
            >
              {{ t('button.nextTask') }}
            </s-btn>
          </div>
        </div>
      </div>
      <!-- End Assignment Info & Task Navigation -->

      <student-feedback-area
        v-bind="$props"
        :grade-display="assignment.course.gradeDisplay"
        :task-weight="taskWeight"
        :part-grades="canViewGradeBreakdown ? partGrades : []"
      />
    </div>
  </s-page-block>
</template>

<script setup lang="ts">
import 'mathlive';
import LegacyTask from '@/components/tasks/LegacyTask.vue';
import {computed, nextTick, ref, shallowRef, toRef, watch} from 'vue';
import {Grade} from '../../types/entities/base-grade';
import {Task, TaskFeedback, TaskResponse, TaskState} from '../../types/entities/tasks';
import {Assignment} from '../../types/entities/assignment';
import {route} from 'ziggy-js';
import {User} from '../../types/entities/user';
import SLink from '../../components/SLink.vue';
import {Head, router, usePage} from '@inertiajs/vue3';
import SResponseAttachmentList from '../../components/SResponseAttachmentList.vue';
import SBadge from '../../design-system/SBadge.vue';
import SPageBlock from '../../design-system/SPageBlock.vue';
import Timer from './Show/Timer.vue';
import AssignmentInfoStudent from './Show/AssignmentInfoStudent.vue';
import DraftStatusAction from './Show/DraftStatusAction.vue';
import AssignmentGradeDto = App.DTOs.AssignmentGradeDto;
import {useTaskResponseStateColors} from './Show/task-response-state-colors';
import {StoreSubmitResponsePayload, useStoreSubmitResponse} from './Show/useStoreSubmitResponse';
import {useResponseData} from './Show/useResponseData';
import {useSubmitButtonState} from './Show/useSubmitButtonState';
import STooltip from '../../design-system/STooltip.vue';
import {AssignmentExtension} from '../../types/entities/assignment-extension';
import PopupPeriodicTable from '../../components/chem/PopupPeriodicTable.vue';
import SBtn from '../../design-system/SBtn.vue';
import StudentFeedbackArea from './Show/StudentFeedbackArea.vue';
import ResponseLegendButton from './Show/ResponseLegendButton.vue';
import ResponseInfoArea from './Show/ResponseInfoArea.vue';
import {useI18n} from 'vue-i18n';
import SIcon from '../../design-system/SIcon.vue';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import CourseLikeAssignmentDto = App.DTOs.CourseAssignments.CourseLikeAssignmentDto;
import TaskGradeDto = App.DTOs.TaskGradeDto;
import {useFlash} from '../../composables/useFlash';
import TaskCriterionGradeDto = App.DTOs.Entities.Grades.TaskCriterionGradeDto;
import STextbookLink from '../../components/STextbookLink.vue';
import AssignmentTaskDto = App.DTOs.Tasks.AssignmentTaskDto;
import {useUserActivityLoggingForSimEvents} from '../../composables/useSimEvents';

// Initialize the MathLive library
(window.mathVirtualKeyboard as any).targetOrigin = '*';

type PartialTaskResponse = {id: number} & Pick<
  TaskResponse,
  | 'createdAt'
  | 'updatedAt'
  | 'submittedAt'
  | 'isDraft'
  | 'hasAttachments'
  | 'isSelfSubmitted'
  | 'lastSavedAt'
  | 'submittedBy'
  | 'user'
>;

const alwaysChanges = [
  'errors',
  'flash',
  'canViewGrades',
  'gradingRunId',
  'storedResponse',
  'responseGrade',
  'partGrades',
  'feedbackByPart',
  'studentAssignmentTaskGrades',
  'timer',
  'autoSaveResponseFeatureIsEnabled',
];
const taskProps = [
  ...alwaysChanges,
  'task',
  'taskResponses',
  'taskState',
  'maxAttempts',
  'additionalAttempts',
];
const responseProps = [...alwaysChanges];
const storeProps = [
  ...responseProps,
  'taskResponses',
  'draftTaskIds',
  'attemptedTaskIds',
  'assignmentGrade',
  'taskIdsWithAttachments',
];
const flash = useFlash();
const props = defineProps<{
  // Validation errors
  errors?: Record<string, string>;
  // Timer
  timer: UserAssignmentTimerDto | null;
  // Features
  autoSaveResponseFeatureIsEnabled: boolean;
  useVaporFileHandling: boolean;
  // Permissions
  canViewGradeBreakdown: boolean;
  canUpdateTask: boolean;
  canViewGrades: boolean;

  // Course
  courseLikeAssignment: CourseLikeAssignmentDto | null;

  // Assignment
  assignment: Assignment;
  assignmentTasks: AssignmentTaskDto[];
  assignmentGrade: AssignmentGradeDto;
  extension: AssignmentExtension | null;

  // Student
  student: User;
  attemptedTaskIds: number[];
  draftTaskIds: number[];
  taskIdsWithAttachments: number[];

  // Task
  task: Task;
  taskState: TaskState;
  taskResponses: PartialTaskResponse[];
  studentAssignmentTaskGrades: Grade[];
  maxAttempts: number | null;
  additionalAttempts: number;

  // Response
  gradingRunId: number | null;
  storedResponse: TaskResponse | null;
  responseGrade: TaskGradeDto | null;
  feedbackByPart: Record<string, TaskFeedback[]>;
  partGrades: TaskCriterionGradeDto[];
}>();

const page = usePage<{
  loggedInUser: {
    id: number;
    isSuperUser: boolean;
  };
}>();

/**
 * Translation
 */
const {t} = useI18n({
  inheritLocale: true,
  useScope: 'local',
});

/**
 * !!! IMPORTANT: We make a copy of the response to work with on initial load of the page.
 *
 * The working response is what we will bind to the task forms, and listen to when submitting changes to the server.
 * We don't want to mutate the page's storedResponse prop, and we don't want draft saves/submissions to be re-pushed into this ref as they could pave over changes the user made between saving/submitting their work, and the server's response.
 *
 * There are two key times when we DO NEED TO UPDATE the workingResponse copy from what the server has pushed down in the storedResponse prop:
 * 1. The task changes.
 * 2. The user manually selects a different response (handled in the below)
 */
const workingResponseCopy = ref(props.storedResponse);
const filesToUpload = shallowRef<File[]>([]);

watch(toRef(props, 'task'), (newTask, oldTask) => {
  // If the task changes, we need to reset the working response copy and clear out the attachments buffer.
  if (newTask.id !== oldTask.id) {
    workingResponseCopy.value = props.storedResponse;
    filesToUpload.value = [];
  }
});

const autoDraftStoreIsEnabled = toRef(props.autoSaveResponseFeatureIsEnabled);
watch(toRef(props, 'autoSaveResponseFeatureIsEnabled'), (newValue) => {
  autoDraftStoreIsEnabled.value = newValue;
});

const selectedResponseId = computed(
  () => props.storedResponse?.id || props.taskResponses[0]?.id || null
);

const selectedResponse = computed<PartialTaskResponse | null>({
  get: () =>
    props.taskResponses.find((response) => response.id === selectedResponseId.value) ?? null,
  set: (newResponse) => {
    if (!newResponse) {
      return;
    }

    router.visit(
      route('web.courses.assignments.tasks.responses.show', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
        task: props.task.id,
        response: newResponse.id,
      }),
      {
        only: responseProps,
        preserveState: true,
        onSuccess: () => {
          // !!!IMPORTANT: After the user has manually selected a different response, we need to update the working response copy.
          workingResponseCopy.value = props.storedResponse;
        },
      }
    );
  },
});

const numTaskAttempts = computed(() => {
  return props.taskResponses.filter((response) => !!response.submittedAt).length;
});

const assignmentTotalMarks = computed(() => {
  if (!props.assignmentTasks.length) {
    return 0;
  }
  return props.assignmentTasks.reduce((a, b) => a + b.pointValue, 0);
});

const periodicTableVisible = ref(false);

const numAttemptedTasks = computed(() => {
  return props.attemptedTaskIds.length;
});

const finalAttempt = computed(() => {
  if (!props.maxAttempts) {
    return false;
  }

  return numTaskAttempts.value == props.maxAttempts + props.additionalAttempts - 1;
});

const noMoreAttempts = computed(() => {
  if (!props.maxAttempts) {
    return false;
  }

  return numTaskAttempts.value >= props.maxAttempts + props.additionalAttempts;
});

const taskNumber = computed(() => {
  return props.assignmentTasks.findIndex((task) => task.id === props.task.id) + 1;
});

const currentTask = computed(() => props.assignmentTasks[taskNumber.value - 1]);

const taskWeight = computed(() => currentTask.value?.pointValue || 0);

const taskHasAttachment = (taskId: number) => {
  return !!props.taskIdsWithAttachments.find((v) => v == taskId);
};

function prevTask() {
  const currentTaskIndex = taskNumber.value - 1;
  const prevTask = props.assignmentTasks[currentTaskIndex - 1];
  if (prevTask) {
    router.visit(
      route('web.courses.assignments.tasks.show', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
        task: prevTask.id,
      }),
      {
        only: taskProps,
        preserveState: true,
      }
    );
  }
}

function nextTask() {
  const currentTaskIndex = taskNumber.value - 1;
  const nextTask = props.assignmentTasks[currentTaskIndex + 1];
  if (nextTask) {
    router.visit(
      route('web.courses.assignments.tasks.show', {
        course: props.assignment.course.id,
        assignment: props.assignment.id,
        task: nextTask.id,
      }),
      {
        only: taskProps,
        preserveState: true,
      }
    );
  }
}

const {determineTaskNavItemClasses} = useTaskResponseStateColors(props);
// It's important we use the working response copy here, and not the server's stored response.
const {responseData, isDirty} = useResponseData(workingResponseCopy);
const {isStoreSubmitDisabled, submitTooltipText} = useSubmitButtonState(
  computed(() => ({
    task: props.task,
    storedResponse: props.storedResponse,
    noMoreAttempts: noMoreAttempts.value,
  })),
  isDirty
);

// Registers logging for sim events
useUserActivityLoggingForSimEvents(toRef(props, 'assignment'), toRef(props, 'task'));

/**
 * Response submission
 */
const {
  submitResponse,
  debouncedStoreDraft,
  storingDraft,
  uploadingFiles,
  submittingResponse,
  experiencedFileUploadFailure,
} = useStoreSubmitResponse(
  toRef(props, 'task'),
  autoDraftStoreIsEnabled,
  isStoreSubmitDisabled,
  storeProps,
  props.useVaporFileHandling
);

const persistAfterTick = (persistFn: (payload: StoreSubmitResponsePayload) => unknown) => {
  // We ensure that all refs are updated, before executing the persist function.
  nextTick(() => {
    persistFn({
      courseId: props.assignment.course.id,
      assignmentId: props.assignment.id,
      taskId: props.task.id,
      gradingRunId: props.gradingRunId,
      taskStateId: props.taskState?.id,
      currentlyStoredAttachments: props.storedResponse?.attachments ?? [],
      data: responseData.value,
      files: filesToUpload.value,
      onSuccess() {
        filesToUpload.value = [];
      },
    });
  });
};

watch(experiencedFileUploadFailure, (justFailed, previouslyFailed) => {
  if (justFailed) {
    flash?.value?.set('error', t('messages.failedToUploadFiles'));
  } else if (previouslyFailed) {
    flash?.value?.dismiss('error');
  }
});

const showProcessingBadge = computed(() => {
  return storingDraft.value || uploadingFiles.value;
});
</script>
<i18n>
{
  "en": {
    "badges": {
      "savingDraft": "Saving Draft...",
      "uploadingFiles": "Uploading Files..."
    },
    "button": {
      "submitResponse": "Submit Response",
      "nextTask": "Next Task",
      "previousTask": "Previous Task",
      "periodicTable": "Open Periodic Table"
    },
    "messages": {
      "failedToUploadFiles": "Failed to upload file(s). Please refresh the page and try again."
    },
    "submitting": "Submitting...",
    "task": "Task",
    "editTask": "Edit Task",
    "metadata": "Metadata",
    "tooltips.xOfYAttemptsMade": "{count} / {max} attempts made. ",
    "tooltips.attemptsBreakdown": "({base} base + {additional} additional)",
    "noMoreAttempts": "No More Attempts",
    "finalAttempt": "Final Attempt"
  },
  "fr": {
    "badges": {
      "savingDraft": "Enregistrement du brouillon...",
      "uploadingFiles": "Téléchargement des fichiers..."
    },
    "button": {
      "submitResponse": "Soumettre la réponse",
      "nextTask": "Tâche suivante",
      "previousTask": "Tâche précédente",
      "periodicTable": "Ouvrir le tableau périodique"
    },
    "messages": {
      "failedToUploadFiles": "Échec du téléchargement du(des) fichier(s). Veuillez rafraîchir la page et réessayer."
    },
    "submitting": "Soumission en cours...",
    "task": "Tâche",
    "editTask": "Modifier la Tâche",
    "metadata": "Metadata",
    "noMoreAttempts": "Plus d'essais",
    "finalAttempt": "Essai final",
    "tooltips.xOfYAttemptsMade": "{count} / {max} essais effectués. ",
    "tooltips.attemptsBreakdown": "({base} base + {additional} supplémentaires)"
  }
}
</i18n>
