<template>
  <Head :title="t('page.title', {course: course.code})" />
  <edit-course-header :course="course" :canCreate="can.create" />
  <form
    @submit.prevent="
      form.put(
        route('courses.update', {
          course: course.id,
        })
      )
    "
    class="flex-1 flex flex-col"
  >
    <s-page-block size="md" class="flex-1">
      <fieldset class="flex flex-col gap-4">
        <s-input-field
          id="name"
          :label="t('labels.name')"
          v-model="form.name"
          :error="form.errors.name"
          required
        />
        <s-input-field
          id="code"
          :label="t('labels.courseCode')"
          :error="form.errors.code"
          v-model="form.code"
          required
        />
        <s-select-field
          id="institutionId"
          :label="t('institution')"
          :disabled="hasSubCourse"
          v-model="form.institutionId"
          :error="form.errors.institutionId"
          required
        >
          <option v-for="institution in institutions" :value="institution.id">
            {{ institution.name }}
          </option>
        </s-select-field>

        <s-select-field id="gradeDisplay" :label="t('gradeDisplay')" v-model="form.gradeDisplay">
          <option
            v-for="gradeDisplay in gradeDisplayOptions"
            :key="gradeDisplay.value"
            :value="gradeDisplay.value"
          >
            {{ gradeDisplay.label }}
          </option>
        </s-select-field>

        <s-select-field
          id="feedbackDisplayStrategy"
          :label="t('labels.feedbackDisplayStrategy')"
          v-model="form.feedbackDisplayStrategy"
        >
          <option
            v-for="feedbackDisplayStrategy in feedbackDisplayStrategyOptions"
            :key="feedbackDisplayStrategy.value"
            :value="feedbackDisplayStrategy.value"
          >
            {{ feedbackDisplayStrategy.label }}
          </option>
        </s-select-field>

        <s-checkbox
          id="useDetailedMarkingEditor"
          v-model="form.useDetailedMarkingEditor"
          color="blue"
        >
          {{ t('labels.useDetailedMarkingEditor') }}
        </s-checkbox>

        <s-select-field
          id="languagePreference"
          :label="t('labels.languagePreference')"
          v-model="form.languagePreference"
        >
          <option value="en">{{ t('en') }}</option>
          <option value="fr">{{ t('fr') }}</option>
        </s-select-field>

        <fieldset class="flex flex-col sm:flex-row gap-5">
          <s-input-field
            id="startDate"
            :label="t('startDate')"
            type="date"
            v-model="form.startDate"
            :error="form.errors.startDate"
            required
          />
          <s-input-field
            id="endDate"
            :label="t('endDate')"
            type="date"
            v-model="form.endDate"
            :error="form.errors.endDate"
            required
          />
        </fieldset>
      </fieldset>
    </s-page-block>
    <s-form-footer
      :cancelRoute="
        route('courses.show', {
          course: course.id,
        })
      "
      :formProcessing="form.processing"
      :formIsDirty="form.isDirty"
      size="md"
    />
  </form>
</template>
<script setup lang="ts">
import {Head, useForm} from '@inertiajs/vue3';
import {useI18n} from 'vue-i18n';
import {route} from 'ziggy-js';
import GradeDisplay = App.Enums.GradeDisplay;
import {Course} from '../../types/entities/course';
import {Institution} from '../../types/entities/institution';
import SPageBlock from '../../design-system/SPageBlock.vue';
import SInputField from '../../design-system/SInputField.vue';
import SSelectField from '../../design-system/SSelectField.vue';
import SFormFooter from '../../design-system/SFormFooter.vue';
import EditCourseHeader from '../../layouts/EditCourseHeader.vue';
import UpdateCourseDto = App.DTOs.Courses.UpdateCourseDto;
import {formatForDateInput} from '../../format/dates';
import FeedbackDisplayStrategy = App.Enums.FeedbackDisplayStrategy;
import SCheckbox from '../../design-system/SCheckbox.vue';

const props = defineProps<{
  course: Course;
  institutions: Institution[];
  gradeDisplayOptions: {
    label: string;
    value: GradeDisplay;
  }[];
  feedbackDisplayStrategyOptions: {
    label: string;
    value: FeedbackDisplayStrategy;
  }[];
  can: {
    create: boolean;
    viewAnySectionIn: boolean;
  };
  hasSubCourse: boolean;
}>();

const form = useForm<UpdateCourseDto>({
  institutionId: props.course.institution.id,
  name: props.course.name,
  code: props.course.code,
  gradeDisplay: props.course.gradeDisplay,
  feedbackDisplayStrategy: props.course.feedbackDisplayStrategy,
  startDate: formatForDateInput(props.course.startDate),
  endDate: formatForDateInput(props.course.endDate),
  languagePreference: props.course.languagePreference,
  useDetailedMarkingEditor: props.course.useDetailedMarkingEditor,
});

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Edit Course - {course}"
    },
    "labels": {
      "name": "Name",
      "courseCode": "Course Code",
      "feedbackDisplayStrategy": "Feedback Display Strategy",
      "useDetailedMarkingEditor": "Use Detailed Marking Editor",
      "draftsEnabled": "Drafts",
      "languagePreference": "Language"
    }
  },
  "fr": {
    "page": {
      "title": "Modifier le cours - {course}"
    },
    "labels": {
      "name": "Nom",
      "feedbackDisplayStrategy": "Stratégie d'affichage des commentaires",
      "useDetailedMarkingEditor": "Utiliser l'éditeur de marquage détaillé",
      "courseCode": "Code du cours",
      "draftsEnabled": "Brouillons",
      "languagePreference": "Langue"
    }
  }
}
</i18n>
