<template>
  <task-form
    :errors="errors"
    :form="form"
    :institutions="institutions"
    :topics="topics"
    class="fieldset"
    allow-media
  >
    <s-input-field
      id="heading"
      label="Heading"
      description="Displayed at the top of the task in large font. This is the title visible to students."
      :required="false"
      v-model="form.ui.heading"
      :error="errors?.ui?.heading"
    />
    <s-field id="uiContext" label="Context Items" :error="errors?.ui?.uiContext">
      <fieldset class="flex flex-col mt-2">
        <div class="flex gap-2 items-end justify-between mb-4">
          <s-btn
            @click.prevent="addUiContext()"
            :disabled="form.ui.uiContext.length >= 25"
            class="mt-2"
            icon="plus"
            size="sm"
          >
            Add Displayed Context Item
          </s-btn>
        </div>
        <vue-draggable
          v-model="form.ui.uiContext"
          group="questions"
          tag="transition-group"
          animation="200"
          ghost-class="ghost-item"
          drag-class="dragging-item"
          handle=".cursor-grab"
          :component-data="{
            tag: 'div',
            class: 'card flex flex-col divide-y divide-gray-200 mb-2 overflow-hidden',
          }"
        >
          <template #item="{element, index}">
            <div
              :key="element.key"
              class="flex items-stretch divide-x divide-gray-150 bg-white even:bg-gray-50"
            >
              <div
                class="flex-1 flex items-center cursor-grab px-2.5 transition hover:text-blue-500 hover:bg-gray-200/30"
              >
                <mdicon name="drag-vertical" class="opacity-70" width="22" height="22" />
              </div>
              <div class="px-4 pt-2.5 pb-3.5 flex flex-col gap-2 w-full">
                <s-select-field
                  v-model="form.ui.uiContext[index].contextType"
                  @update:model-value="updateContextName(index)"
                  label="Context Type"
                  id="contextType"
                >
                  <option
                    v-for="(label, key) in UI_DISPLAYED_CONTEXT_LABELS"
                    :value="key"
                    :disabled="
                      (key === 'randomized_passage' && hasRandomizedPassage) ||
                      (key === 'file_upload' && hasFileUpload) ||
                      (key === 'image' && !form.id)
                    "
                  >
                    {{ label }}
                  </option>
                </s-select-field>
                <s-input-field
                  v-model="form.ui.uiContext[index].name"
                  id="name"
                  label="Context Name"
                  :error="errors?.ui?.uiContext?.[index]?.name"
                />
                <div v-if="form.ui.uiContext[index].contextType == 'text_block'">
                  <s-textarea-field
                    class="mb-2"
                    id="text"
                    label="Text Displayed to Student"
                    :tooltip="
                      isLabel(form.ui.uiContext[index].uuid)
                        ? 'This is being used as a label for a student response area. It will render above the response input as a label.'
                        : null
                    "
                    v-model="form.ui.uiContext[index].text"
                    :error="errors?.ui?.uiContext?.[index]?.text"
                  />
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'student_response'">
                  <s-select-field
                    id="labelby"
                    label="Label By"
                    v-model="form.ui.uiContext[index].labelby"
                    :error="errors?.ui?.uiContext?.[index]?.labelby"
                    class="mb-3"
                  >
                    <option
                      v-if="
                        !form.ui.uiContext.some((context) => context.contextType === 'text_block')
                      "
                      disabled
                      value=""
                    >
                      Add a text block context to use as a label
                    </option>
                    <template v-for="(context, ctxIndex) in form.ui.uiContext">
                      <option :value="context.uuid" v-if="context.contextType == 'text_block'">
                        {{ context.name }}
                      </option>
                    </template>
                  </s-select-field>
                  <s-field id="showCharacterLimit" label="Character Limit to Student Response">
                    <div class="flex items-center space-x-2">
                      <s-button-toggle
                        id="showCharacterLimit"
                        :options="[
                          {value: null, label: 'No'},
                          {value: form.ui.uiContext[index].characterLimit ?? '0', label: 'Yes'},
                        ]"
                        v-model="form.ui.uiContext[index].characterLimit"
                      />
                      <template v-if="form.ui.uiContext[index].characterLimit !== null">
                        <s-input-field
                          id="characterLimit"
                          :required="false"
                          type="number"
                          min="0"
                          v-model="form.ui.uiContext[index].characterLimit"
                        />
                      </template>
                    </div>
                  </s-field>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'dynamic_table'">
                  <s-textarea-field
                    class="mb-2"
                    id="text"
                    label="Table Headers"
                    description="Comma separated list of headers. The list size will determine the initial number of columns."
                    v-model="form.ui.uiContext[index].text"
                    :error="errors?.ui?.uiContext?.[index]?.text"
                  ></s-textarea-field>
                  <div class="flex flex-wrap gap-4">
                    <s-field
                      id="allowEditableHeaders"
                      label="Allow student to edit table header titles"
                    >
                      <s-button-toggle
                        id="allowEditableHeaders"
                        :options="[
                          {value: false, label: 'No'},
                          {value: true, label: 'Yes'},
                        ]"
                        v-model="form.ui.uiContext[index].allowEditableHeaders"
                      ></s-button-toggle>
                    </s-field>
                    <s-field id="allowDynamicColumns" label="Allow student to add/remove columns">
                      <s-button-toggle
                        id="allowDynamicColumns"
                        :options="[
                          {value: false, label: 'No'},
                          {value: true, label: 'Yes'},
                        ]"
                        v-model="form.ui.uiContext[index].allowDynamicColumns"
                      ></s-button-toggle>
                    </s-field>
                  </div>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'image'">
                  <s-select-field
                    id="fileUploadId"
                    label="Image"
                    description="Images uploaded and saved in the media manager will display here"
                    :model-value="form.ui.uiContext[index].imageFileUploadId"
                    @update:model-value="form.ui.uiContext[index].imageFileUploadId = $event"
                    :error="errors?.ui?.uiContext?.[index]?.imageFileUploadId"
                  >
                    <option
                      v-for="file in form.files?.filter((file: TaskFileUploadDto) =>
                        isImageMimeType(file.mimetype)
                      )"
                      :value="file.id"
                      :disabled="!!(file.temp || file.deleted)"
                    >
                      {{ file.temp ? file.temp.originalFilename : file.name }}

                      <template v-if="file.temp">
                        (Unsaved. Can't use until you save the task.)
                      </template>
                    </option>
                  </s-select-field>

                  <s-input-field
                    id="alt"
                    label="Alt"
                    description="Describe the image to ensure accessibility"
                    :error="errors?.ui?.uiContext?.[index]?.imageAltText"
                    :model-value="form.ui.uiContext[index].imageAltText"
                    @update:model-value="form.ui.uiContext[index].imageAltText = $event as string"
                  />

                  <s-input-field
                    id="width"
                    label="Width"
                    description="Desired image width"
                    :error="errors?.ui?.uiContext?.[index]?.imageWidth"
                    :model-value="form.ui.uiContext[index].imageWidth"
                    @update:model-value="form.ui.uiContext[index].imageWidth = $event as string"
                  />

                  <s-field
                    v-if="form.ui.uiContext[index].imageFileUploadId"
                    label="Preview"
                    id="preview"
                  >
                    <div class="flex">
                      <img
                        :src="
                          route('tasks.files.show', {
                            task: form.id,
                            fileUpload: form.ui.uiContext[index].imageFileUploadId,
                          })
                        "
                        :alt="form.ui.uiContext[index].imageAltText ?? undefined"
                        :width="form.ui.uiContext[index].imageWidth ?? undefined"
                        class="max-w-full h-auto"
                      />
                    </div>
                  </s-field>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'file_upload'">
                  <s-input-field
                    id="label"
                    label="Label"
                    description="Label for the file upload input"
                    v-model="form.ui.uiContext[index].label"
                    :error="errors?.ui?.uiContext?.[index]?.label"
                    class="mb-2"
                  />
                  <s-input-field
                    v-model="form.ui.uiContext[index].acceptedFileExtensions"
                    id="text"
                    label="Allowed File Extensions"
                    :error="errors?.ui?.uiContext?.[index]?.acceptedFileExtensions"
                    description="A comma separated list of allowed file extensions. Leave this blank to allow all file types. Ex) '.pdf, ...'"
                    class="mb-2"
                  />
                  <div class="flex flex-wrap gap-4">
                    <s-input-field
                      id="maxNumberOfFiles"
                      v-model.number="form.ui.uiContext[index].maxNumberOfFiles"
                      type="number"
                      label="Maximum Number of Files"
                      tooltip="Leave blank for unlimited."
                      :error="errors?.ui?.uiContext?.[index]?.maxNumberOfFiles"
                    />
                    <s-input-field
                      id="maxFileSize"
                      v-model.number="form.ui.uiContext[index].maxFileSize"
                      type="number"
                      label="Maximum File Size"
                      suffix="MB"
                      :error="errors?.ui?.uiContext?.[index]?.maxFileSize"
                    />
                  </div>
                  <div v-if="showAiGradedFileLimitationsWarning" class="flex flex-wrap gap-4">
                    <p class="mt-3 text-amber-600">
                      Settings may cause problems if using files as AI Grading Context. AI grading
                      supports max of 3 files, 20 MB per file, and the following extensions:
                      {{ [...aiImageGradableFileExtensions].join(',') }}
                    </p>
                  </div>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'multiple_choice'">
                  <s-textarea-field
                    id="text"
                    label="Question"
                    description="The question to be displayed to the student."
                    v-model="form.ui.uiContext[index].text"
                    :error="errors?.ui?.uiContext?.[index]?.text"
                    class="mb-2"
                  />
                  <s-form-list
                    title="Options"
                    addLabel="Add Option"
                    :addItem="
                      () =>
                        form.ui.uiContext[index].multipleChoiceOptions.push({key: '', label: ''})
                    "
                    :items="form.ui.uiContext[index].multipleChoiceOptions"
                    :removeItem="
                      (item: any, itemIndex: number) =>
                        form.ui.uiContext[index].multipleChoiceOptions.splice(itemIndex, 1)
                    "
                    :sortable="true"
                    placeholder="No Options Added"
                    class="mb-2"
                  >
                    <template #item="{index: optIndex}">
                      <div class="p-5">
                        <div
                          class="flex-1 w-full flex flex-wrap gap-x-4 gap-y-3 justify-start mb-3"
                        >
                          <div class="flex-1 max-w-[10rem] grow">
                            <s-input-field
                              :id="`options.${optIndex}.key`"
                              label="Key"
                              v-model="form.ui.uiContext[index].multipleChoiceOptions[optIndex].key"
                              :error="
                                errors?.ui?.uiContext?.[index]?.multipleChoiceOptions?.[optIndex]
                                  ?.key
                              "
                            />
                          </div>
                          <div class="flex-1 grow-[2]">
                            <s-input-field
                              :id="`options.${optIndex}.label`"
                              label="Label"
                              v-model="
                                form.ui.uiContext[index].multipleChoiceOptions[optIndex].label
                              "
                              :error="
                                errors?.ui?.uiContext?.[index]?.multipleChoiceOptions?.[optIndex]
                                  ?.label
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </template>
                  </s-form-list>
                  <div class="flex flex-wrap gap-4">
                    <s-input-field
                      id="multipleChoicePointsAvailable"
                      v-model.number="form.ui.uiContext[index].weight"
                      type="number"
                      label="Points Available (optional)"
                      tooltip="When this is not set, the question will be worth 0 points."
                      :error="errors?.ui?.uiContext?.[index]?.weight"
                    />
                    <s-select-field
                      v-model="form.ui.uiContext[index].correctMultipleChoiceAnswer"
                      label="Correct Option"
                      id="correctMultipleChoiceAnswer"
                      :error="errors?.ui?.uiContext?.[index]?.correctMultipleChoiceAnswer"
                    >
                      <option
                        v-for="option in form.ui.uiContext[index].multipleChoiceOptions"
                        :value="option.key"
                      >
                        {{ option.label }}
                      </option>
                    </s-select-field>
                  </div>
                </div>
                <div v-else-if="form.ui.uiContext[index].contextType == 'randomized_passage'">
                  <s-btn @click.prevent="addTextPassage(index)" class="mt-2" icon="plus" size="sm">
                    Add Passage
                  </s-btn>
                  <div
                    v-for="(textPassage, passageIndex) in form.ui.uiContext[index]
                      .randomTextPassages"
                    :key="index"
                  >
                    <s-textarea-field
                      class="mb-2 w-full"
                      id="text"
                      label="Text Passage"
                      v-model="form.ui.uiContext[index].randomTextPassages[passageIndex]"
                      :error="errors?.ui?.uiContext?.[index]?.randomTextPassages?.[passageIndex]"
                    />
                    <button
                      @click.prevent="removeTextPassage(index, passageIndex)"
                      class="flex items-center border-right-1 px-3 text-red-500 hover:bg-red-100/30 transition"
                    >
                      <mdicon name="trash-can" class="opacity-70" width="18" height="18" />
                    </button>
                  </div>
                </div>
              </div>
              <button
                @click.prevent="form.ui.uiContext.splice(index, 1)"
                class="flex items-center border-right-1 px-3 text-red-500 hover:bg-red-100/30 transition"
              >
                <mdicon name="trash-can" class="opacity-70" width="18" height="18" />
              </button>
            </div>
          </template>
        </vue-draggable>
      </fieldset>
    </s-field>
    <s-select-field
      description="After selecting a preset, feedback instructions can be viewed and altered."
      v-model="form.grader.questionType"
      @update:model-value="updateQuestionType()"
      label="Choose Preset Feedback Instructions"
      id="questionType"
      :error="errors?.grader?.questionType"
    >
      <option v-for="(label, key) in AiQuestionTypeOptions" :value="key">
        {{ label }}
      </option>
    </s-select-field>

    <div class="flex justify-between" v-if="form.grader.questionType">
      <FeedbackInstructionsBuilder
        v-model:modelValue="form.grader.feedbackInstructions"
        :errors="errors?.grader?.feedbackInstructions"
      />
    </div>

    <div
      class="flex justify-between"
      v-if="form.grader.questionType && page.props.loggedInUser.isSuperUser"
    >
      <s-field id="llmConfig" label="LLM Configuration" :errors="errors?.grader?.llmOptions" />
      <LlmOptionsConfiguration
        v-if="form.grader.llmOptions"
        v-model="form.grader.llmOptions"
        :modelsMetadata="modelsMetadata"
        :errors="errors?.grader?.llmOptions"
      />
    </div>

    <s-select-field
      description="After selecting a strategy, questions and grading instructions can be added."
      v-model="form.grader.gradeCalculationStrategy"
      label="Grade Calculating Strategy"
      id="gradeCalculationStrategy"
      :error="errors?.grader?.gradeCalculationStrategy"
    >
      <option v-for="(label, key) in gradeCalculationStrategies" :value="key">
        {{ label }}
      </option>
    </s-select-field>

    <s-field
      id="graders"
      v-if="form.grader.gradeCalculationStrategy"
      label="AI Graders"
      :error="errors?.grader?.questions"
    >
      <div
        class="card padded"
        v-for="(question, questionIdx) in form.grader.questions"
        :key="questionIdx"
      >
        <s-field
          id="linkedStudentAnswer"
          label="Linked Context/Response"
          description="The responses/context to expose to the Ai Grading"
          class="mb-4"
          :error="errors?.grader?.questions?.[questionIdx]?.linkedStudentAnswers"
        >
          <div
            v-for="(uiContext, ctxIndex) in form.ui.uiContext"
            :key="ctxIndex"
            class="flex items-center"
          >
            <input
              type="checkbox"
              :value="uiContext.uuid"
              v-model="form.grader.questions[questionIdx].linkedStudentAnswers"
            />
            <label class="ml-2">{{ uiContext.name }}</label>
          </div>
        </s-field>
        <s-field
          class="mb-4"
          id="criteria"
          label="Grading Instructions"
          :error="errors?.grader?.criteria"
        >
          <div
            class="card padded"
            v-for="(criteria, criteriaIdx) in question.criteria"
            :key="criteriaIdx"
          >
            <s-input-field
              id="text"
              :label="question.criteria.length === 1 ? 'Feedback Part Title' : 'Criteria Name'"
              v-model="criteria.criteriaName"
              :error="
                errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]?.criteriaName
              "
            />
            <s-textarea-field
              id="text"
              :label="question.criteria.length === 1 ? 'Grading Rubric' : 'Criteria Item Rubric'"
              v-model="criteria.criteriaDescription"
              :error="
                errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]
                  ?.criteriaDescription
              "
            />
            <div class="flex flex-wrap gap-4">
              <div class="min-w-52 flex-1">
                <grade-increment-field
                  v-if="
                    form.grader.gradeCalculationStrategy === 'standard_with_grade_breakdown' ||
                    form.grader.gradeCalculationStrategy === 'standard'
                  "
                  v-model="criteria.gradeIncrement"
                  :errors="
                    errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]
                      ?.gradeIncrement
                  "
                />
                <s-input-field
                  id="score"
                  type="number"
                  step="0.01"
                  min="0"
                  max="1"
                  label="Passing Grade Threshold"
                  description="Range: [0,1]. Ex) 0.5 implies >50% correct answers are given full mark."
                  v-if="form.grader.gradeCalculationStrategy === 'specification'"
                  v-model="criteria.binaryGradeThreshold"
                  :error="
                    errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]
                      ?.binaryGradeThreshold
                  "
                />
              </div>
              <div class="flex-0 w-40">
                <s-input-field
                  id="score"
                  type="number"
                  step="1"
                  min="0"
                  label="Grade Weight"
                  v-model="criteria.gradeWeight"
                  :error="
                    errors?.grader?.questions?.[questionIdx]?.criteria?.[criteriaIdx]?.gradeWeight
                  "
                />
              </div>
            </div>
            <s-btn
              @click.prevent="
                () => {
                  const newCriteria = [...form.grader.questions[questionIdx].criteria];
                  newCriteria.splice(criteriaIdx, 1);
                  form.grader.questions[questionIdx].criteria = newCriteria;
                }
              "
              class="mt-2"
              icon="trash-can"
              size="sm"
              color="danger"
              :disabled="form.grader.questions[questionIdx].criteria.length === 1"
            >
              Remove Criteria Item
            </s-btn>
          </div>
          <s-btn
            @click.prevent="
              () => {
                const newCriteria = [...form.grader.questions[questionIdx].criteria];
                newCriteria.push({
                  criteriaName: '',
                  criteriaDescription: '',
                  gradeIncrement: 0.25,
                  gradeWeight: 1,
                  binaryGradeThreshold: null,
                  assignedGrade: null,
                  comment: null,
                });
                form.grader.questions[questionIdx].criteria = newCriteria;
              }
            "
            :disabled="form.grader.questions[questionIdx].criteria.length > 15"
            class="mt-2"
            icon="plus"
            size="sm"
          >
            {{
              form.grader.questions[questionIdx].criteria.length > 1
                ? 'Add Criteria Item'
                : 'Evaluate This Question On Multiple Rubric Items (Criteria)'
            }}
          </s-btn>
        </s-field>
        <marking-examples
          v-model="form.grader.questions[questionIdx].markingExamples"
          :criteria="form.grader.questions[questionIdx].criteria"
          :errors="errors?.grader?.questions?.[questionIdx]?.markingExamples"
        />
        <s-btn
          @click.prevent="removeQuestion(questionIdx)"
          :disabled="form.grader.questions.length === 1"
          class="mt-2"
          icon="trash-can"
          size="sm"
          color="danger"
        >
          Remove Question
        </s-btn>
      </div>
      <s-btn
        @click.prevent="addQuestion()"
        :disabled="form.grader.questions.length >= 10"
        class="mt-2"
        icon="plus"
        size="sm"
      >
        Add Question
      </s-btn>
    </s-field>
  </task-form>
</template>
<script lang="ts" setup>
import {computed, watch} from 'vue';
import SInputField from '../../../design-system/SInputField.vue';
import SBtn from '../../../design-system/SBtn.vue';
import FeedbackInstructionsBuilder from '../../../components/tasks/ai-parts/FeedbackInstructionsBuilder.vue';
import SButtonToggle from '../../../design-system/SButtonToggle.vue';
import SField from '../../../design-system/SField.vue';
import STextareaField from '../../../design-system/STextareaField.vue';
import VueDraggable from 'vuedraggable';
import SSelectField from '../../../design-system/SSelectField.vue';
import {v4 as uuidV4} from 'uuid';
import SFormList from '../../../design-system/SFormList.vue';
import GradeIncrementField from '../../../components/tasks/ai-parts/GradeIncrementField.vue';
import {useValidationErrors} from '../../../util/validation-errors';
import TaskForm from '../../../forms/TaskForm.vue';
import {useForm, usePage} from '@inertiajs/vue3';
import {route} from 'ziggy-js';
import {isImageMimeType} from '../../../util/images';
import {LlmMetadata} from '../../../types/entities/llmMetadata';
import LlmOptionsConfiguration from '../../../components/tasks/ai-parts/LlmOptionsConfiguration.vue';
import MarkingExamples from '../../../components/tasks/ai-parts/MarkingExamples.vue';
import {Institution} from '../../../types/entities/institution';
import AiSelectableContextQuestionFormDto = App.DTOs.Tasks.AiSelectableContextQuestionFormDto;
import AiSelectableContextQuestionDto = App.DTOs.Tasks.AiSelectableContextQuestionDto;
import FeedbackInstructionsDto = App.DTOs.Tasks.FeedbackInstructionsDto;
import AiQuestionType = App.Enums.AiQuestionType;
import OptionDto = App.DTOs.Tasks.OptionDto;
import UiDisplayedContextDto = App.DTOs.Tasks.UiDisplayedContextDto;
import UiDisplayedContextType = App.Enums.UiDisplayedContextType;
import AiGradeCalculationStrategy = App.Enums.AiGradeCalculationStrategy;
import FeedbackLanguage = App.Enums.PromptBuildingPhrases.FeedbackLanguage;
import TaskFileUploadDto = App.DTOs.Tasks.TaskFileUploadDto;
import OpenAiRequestConfigDto = App.DTOs.OpenAiRequestConfigDto;

const props = defineProps<{
  modelValue?: AiSelectableContextQuestionFormDto;
  isCopy?: boolean;
  modelsMetadata: LlmMetadata[];
  institutions: Institution[];
  topics: TaskTopicDtoWithLineage[];
  errors?: Record<string, any>;
}>();

const page = usePage<{
  loggedInUser: {
    isSuperUser: boolean;
  };
}>();

const {errors} = useValidationErrors(props, 'errors');

const emit = defineEmits<{
  (name: 'update:modelValue', payload: AiSelectableContextQuestionFormDto): void;
  (name: 'submit'): void;
}>();

const UI_DISPLAYED_CONTEXT_LABELS: Record<UiDisplayedContextType, string> = {
  text_block: 'Text Block',
  student_response: 'Student Response Area',
  multiple_choice: 'Multiple Choice',
  randomized_passage: 'Randomized Passage',
  dynamic_table: 'Dynamic Table',
  file_upload: 'File Upload',
  image: 'Image',
};

const form = useForm<AiSelectableContextQuestionFormDto>(
  () =>
    props.modelValue || {
      title: '',
      textbookLink: null,
      textbookLinkText: null,
      textbookLinkIcon: null,
      youtubeId: null,
      type: 'AiSelectableContextQuestion',
      status: 'draft',
      ui: {
        uiContext: [] as UiDisplayedContextDto[],
        heading: null,
      },
      grader: {
        questions: [] as AiSelectableContextQuestionDto[],
        feedbackInstructions: {
          subject: '',
          provideMistakeLabels: true,
          tone: null,
          formality: null,
          gradeTuning: null,
          improvementStrategy: null,
          gradeExplanation: null,
          answerRevealStrategy: 'Formative feedback',
          feedbackLength: null,
          useCustomBasePrompt: false,
          maxPastContextLength: 2,
          feedbackLanguagePhrases: [] as FeedbackLanguage[],
          customPhrases: [''],
        } as FeedbackInstructionsDto,
        gradeCalculationStrategy: 'standard_with_grade_breakdown',
        questionType: 'custom',
        llmOptions: defaultLlmOptions,
      },
      draftable: true,
      files: [],
      institutionId: null,
      topicIds: [],
    }
);

const defaultModel =
  props.modelsMetadata?.find((metadata) => metadata.model === 'gpt-4o-2024-08-06') || null;
const defaultLlmOptions: OpenAiRequestConfigDto = {
  modelId: Number(defaultModel?.id ?? 1),
  maxOutputTokens: 2000,
  temperature: 0.0,
  topP: 1.0,
  jsonResponse: true,
  jsonSchema: null,
};

// Keep the linkedStudentAnswers in sync with the available uiContext
watch(
  () => form.ui.uiContext,
  (newUiContextArray, oldUiContextArray) => {
    form.grader.questions.forEach((contextGrader) => {
      contextGrader.linkedStudentAnswers = contextGrader.linkedStudentAnswers.filter(
        (linkedStudentAnswerId) =>
          newUiContextArray.some((uiContext, ctxIndex) => linkedStudentAnswerId === uiContext.uuid)
      );
    });
  },
  {deep: true}
);
// Ensure there are default values for the LLM options
watch(
  () => form.grader.llmOptions,
  (newVal) => {
    if (newVal === null) {
      form.grader.llmOptions = defaultLlmOptions;
    }
  },
  {immediate: true}
);

const aiImageGradableFileExtensions = new Set(['.png', '.webp', '.jpg', '.jpeg']);

const showAiGradedFileLimitationsWarning = computed(() => {
  const fileUpload = form.ui.uiContext.find((context) => context.contextType === 'file_upload');

  if (!fileUpload) {
    return false;
  }

  const meetsFileNumberLimit = !!fileUpload.maxNumberOfFiles && fileUpload.maxNumberOfFiles <= 3;
  const meetsFileSizeLimit = !!fileUpload.maxFileSize && fileUpload.maxFileSize <= 20.0;
  const meetsExtensionRecommendation =
    !!fileUpload.acceptedFileExtensions &&
    fileUpload.acceptedFileExtensions
      .split(',')
      .every((ext) => aiImageGradableFileExtensions.has(ext.trim()));

  return !(meetsFileNumberLimit && meetsFileSizeLimit && meetsExtensionRecommendation);
});

const gradeCalculationStrategies: Record<AiGradeCalculationStrategy, string> = {
  standard: 'Standard grading',
  standard_with_grade_breakdown: 'Standard grading with displayed grade breakdown',
  specification: 'Specification grading (Pass/Fail)',
  no_grade: 'No grade - Only provide feedback (Defaults to 100% grade)',
};

type FilteredQuestionType = Exclude<AiQuestionType, 'fill_in_the_blank'>;

const AiQuestionTypeOptions: Record<FilteredQuestionType, string> = {
  written: 'Written Question',
  criteria: 'Criteria Question',
  specification: 'Specification Question',
  custom: 'Custom',
};

function updateQuestionType() {
  form.grader.llmOptions = defaultLlmOptions;
  switch (form.grader.questionType) {
    case 'written':
      form.grader.feedbackInstructions.tone = 'Authoritative';
      form.grader.feedbackInstructions.formality = 'Formal';
      form.grader.feedbackInstructions.gradeTuning = null;
      form.grader.feedbackInstructions.improvementStrategy = null;
      form.grader.feedbackInstructions.gradeExplanation = null;
      form.grader.feedbackInstructions.answerRevealStrategy = 'Formative feedback';
      form.grader.feedbackInstructions.feedbackLength = 'Concise writing';
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      break;
    case 'criteria':
      form.grader.llmOptions.maxOutputTokens = 4000;
      form.grader.gradeCalculationStrategy = 'standard_with_grade_breakdown';
      form.grader.feedbackInstructions.tone = null;
      form.grader.feedbackInstructions.formality = null;
      form.grader.feedbackInstructions.gradeTuning = null;
      form.grader.feedbackInstructions.improvementStrategy = null;
      form.grader.feedbackInstructions.gradeExplanation = null;
      form.grader.feedbackInstructions.answerRevealStrategy = 'Formative feedback';
      form.grader.feedbackInstructions.feedbackLength = 'Concise writing';
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      break;
    case 'specification':
      form.grader.gradeCalculationStrategy = 'specification';
      form.grader.feedbackInstructions.tone = null;
      form.grader.feedbackInstructions.formality = null;
      form.grader.feedbackInstructions.improvementStrategy = 'Inquiry feedback';
      form.grader.feedbackInstructions.gradeExplanation = 'Explain the grade';
      form.grader.feedbackInstructions.answerRevealStrategy = 'Formative feedback';
      form.grader.feedbackInstructions.feedbackLength = 'Concise writing';
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      break;
    case 'custom':
      form.grader.feedbackInstructions.feedbackLanguagePhrases.push('No empty compliments');
      break;
  }
}

const hasRandomizedPassage = computed(() =>
  form.ui.uiContext.some((context) => context.contextType === 'randomized_passage')
);

const hasFileUpload = computed(() =>
  form.ui.uiContext.some((context) => context.contextType === 'file_upload')
);

function removeQuestion(index: number) {
  form.grader.questions.splice(index, 1);
}

function addQuestion() {
  form.grader.questions.push({
    linkedStudentAnswers: [],
    criteria: [
      {
        criteriaName: '',
        criteriaDescription: '',
        gradeIncrement: 0.05,
        gradeWeight: 1,
        binaryGradeThreshold: null,
        assignedGrade: null,
        comment: null,
      },
    ],
    markingExamples: [],
  });
}

function generateContextName(contextType: UiDisplayedContextType, startingFrom = 1) {
  const cumulativeCounts = form.ui.uiContext.reduce(
    (countsPerType, item) => {
      countsPerType[item.contextType] += 1;
      return countsPerType;
    },
    {
      text_block: startingFrom,
      student_response: startingFrom,
      multiple_choice: startingFrom,
      randomized_passage: startingFrom,
      file_upload: startingFrom,
      dynamic_table: startingFrom,
      image: startingFrom,
    } as Record<UiDisplayedContextType, number>
  );

  return `${UI_DISPLAYED_CONTEXT_LABELS[contextType]} ${cumulativeCounts[contextType]}`;
}

function updateContextName(uiContextIndex: number) {
  const context = form.ui.uiContext[uiContextIndex];
  context.name = generateContextName(context.contextType, 0);
}

function addUiContext() {
  form.ui.uiContext.push({
    uuid: uuidV4(),
    contextType: 'text_block',
    name: generateContextName('text_block'),
    text: null,
    label: null,
    labelby: '',
    characterLimit: null,
    correctMultipleChoiceAnswer: null,
    weight: null,
    acceptedFileExtensions: [...aiImageGradableFileExtensions].join(','),
    maxNumberOfFiles: 3,
    maxFileSize: 20.0,
    allowEditableHeaders: false,
    allowDynamicColumns: false,
    randomTextPassages: [],
    multipleChoiceOptions: [] as OptionDto[],
    imageFileUploadId: null,
    imageWidth: null,
    imageAltText: null,
  });
}

function addTextPassage(ctxIndex: number) {
  const passages = form.ui.uiContext[ctxIndex].randomTextPassages;
  if (Array.isArray(passages)) {
    passages.push('');
  }
}

function removeTextPassage(ctxIndex: number, passageIndex: number) {
  const passages = form.ui.uiContext[ctxIndex].randomTextPassages;
  if (Array.isArray(passages)) {
    passages.splice(passageIndex, 1);
  }
}

function isLabel(uuid: string) {
  return form.ui.uiContext.some(
    (context) => context.contextType === 'student_response' && context.labelby === uuid
  );
}
</script>
