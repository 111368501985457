<template>
  <Head
    :title="
      t('page.title', {course: course.code, assignment: assignment.name, student: student.email})
    "
  ></Head>
  <s-page-block size="md">
    <s-page-header
      :heading="t('page.header', {student: student.firstName + ' ' + student.lastName})"
      class="mb-4"
    >
      <template #actions>
        <s-btn
          v-if="can.create"
          icon="plus"
          :href="
            route('courses.assignments.students.timer_extensions.create', [
              course.id,
              assignment.id,
              student.id,
            ])
          "
        >
          {{ t('actions.add') }}
        </s-btn>
      </template>
    </s-page-header>

    <table v-if="extensions.length" class="table dense">
      <thead>
        <tr>
          <th class="!pt-0">{{ t('time.minutes') }}</th>
          <th class="!pt-0">{{ t('table.header.grantedBy') }}</th>
          <th class="!pt-0">{{ t('table.header.status') }}</th>
          <th class="!pt-0">{{ t('table.header.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="extension in extensions">
          <td>{{ extension.minutes }}</td>
          <td>{{ extension.grantedBy.fullName }}</td>
          <td>
            <div class="flex">
              <s-badge v-if="extension.claimed" color="green" size="sm">
                {{ t('table.status.claimed') }}
              </s-badge>
              <s-badge v-else color="gray" size="sm">
                {{ t('table.status.unclaimed') }}
              </s-badge>
            </div>
          </td>
          <td>
            <div class="flex gap-2" v-if="!extension.claimed">
              <s-btn
                icon="pencil"
                size="sm"
                :href="
                  route('courses.assignments.students.timer_extensions.edit', [
                    course.id,
                    assignment.id,
                    student.id,
                    extension.id,
                  ])
                "
              >
                {{ t('actions.edit') }}
              </s-btn>
              <s-btn icon="delete" size="sm" color="red" @click="openDeleteConfirmation(extension)">
                {{ t('actions.delete') }}
              </s-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <s-placeholder v-else>
      {{ t('page.placeholder') }}
    </s-placeholder>

    <s-modal
      v-model:open="showDeleteModal"
      size="sm"
      :title="t('modal.title')"
      :confirm="{
        label: t('modal.confirm'),
        icon: 'trash-can',
        color: 'red',
      }"
      @confirm="handleDelete"
      @close="showDeleteModal = false"
      cancellable
    >
      <template #content>
        <p>{{ t('modal.content') }}</p>
      </template>
    </s-modal>
  </s-page-block>
</template>
<script setup lang="ts">
import {Head, router} from '@inertiajs/vue3';
import {Assignment} from '../../types/entities/assignment';
import {Course} from '../../types/entities/course';
import {User} from '../../types/entities/user';
import UserAssignmentTimerDto = App.DTOs.UserAssignmentTimerDto;
import SPageBlock from '../../design-system/SPageBlock.vue';
import SPageHeader from '../../design-system/SPageHeader.vue';
import {useI18n} from 'vue-i18n';
import SBtn from '../../design-system/SBtn.vue';
import SBadge from '../../design-system/SBadge.vue';
import SPlaceholder from '../../design-system/SPlaceholder.vue';
import {route} from 'ziggy-js';
import {ref} from 'vue';
import SModal from '../../design-system/SModal.vue';

const props = defineProps<{
  can: {
    create: boolean;
  };
  course: Course;
  assignment: Assignment;
  student: User;
  timer: UserAssignmentTimerDto;
  extensions: any[];
}>();

const {t} = useI18n({
  useScope: 'local',
  inheritLocale: true,
});

const showDeleteModal = ref(false);
const timerExtensionToDelete = ref<{id: number} | null>(null);

const openDeleteConfirmation = (extension: any) => {
  timerExtensionToDelete.value = extension;
  showDeleteModal.value = true;
};

const handleDelete = () => {
  if (!timerExtensionToDelete.value) {
    return;
  }

  router.delete(
    route('courses.assignments.students.timer_extensions.destroy', {
      course: props.course.id,
      assignment: props.assignment.id,
      user: props.student.id,
      timerExtension: timerExtensionToDelete.value.id,
    })
  );
};
</script>
<i18n>
{
  "en": {
    "page": {
      "title": "Timer Extensions - {student} - {assignment} - {course}",
      "header": "Timer Extensions for {student}",
      "placeholder": "No extensions have been granted."
    },
    "table": {
      "header": {
        "grantedBy": "Granted By",
        "status": "Status",
        "actions": "Actions"
      },
      "status": {
        "claimed": "Claimed",
        "unclaimed": "Unclaimed"
      }
    },
    "actions": {
      "add": "Add Extension",
      "edit": "Edit",
      "delete": "Delete"
    },
    "modal": {
      "title": "Confirm Delete",
      "confirm": "Delete Timer Extension",
      "content": "Are you sure you want to delete this Timer Extension?"
    }
  },
  "fr": {
    "page": {
      "title": "Extensions de temps - {student} - {assignment} - {course}",
      "header": "Extensions de temps pour {student}",
      "placeholder": "Aucune extension n'a été accordée."
    },
    "table": {
      "header": {
        "grantedBy": "Accordé par",
        "status": "Statut",
        "actions": "Actions"
      },
      "status": {
        "claimed": "Réclamé",
        "unclaimed": "Non réclamé"
      }
    },
    "actions": {
      "add": "Ajouter une extension",
      "edit": "Modifier",
      "delete": "Supprimer"
    },
    "modal": {
      "title": "Confirmer la suppression",
      "confirm": "Supprimer la prolongation de temps",
      "content": "Êtes-vous sûr de vouloir supprimer cette prolongation de temps?"
    }
  }
}
</i18n>
